import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  dateToEpoch,
  Form,
  Input,
  Label,
  Select,
  TextArea,
  useToast,
} from '@gonurture/design-system';

import { assignmentFormSchema } from 'form-schemas';
import LearningObjectivesFields from './LearningObjectivesFields';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useClassroom, useCurrentAssignment } from 'store/selectors';
import { useDispatch } from 'react-redux';
import { setAppError } from 'store/reducers/app-error-reducer';
import { useErrorHandler } from 'hooks/';
import FileUploader from 'components/attachments/FileUploader';
import {
  addAssignment,
  addAssignmentAttachment,
  updateAssignment,
} from 'store/reducers/assignments-reducer';
import DateInput from 'components/date-input/DateInput';
import StudentsTable from './StudentsTable';
import { useNavigate } from 'react-router-dom';
import Attachments from 'components/attachments/Attachments';
import {
  createAssignment,
  updateAssignment as updateAssignmentApi,
} from 'apis/';

const AssignmentForm = ({ context, defaultAssignmentId }) => {
  const [assignmentCreateLoading, setAssignmentCreateLoading] = useState(false);
  const [assignmentId, setAssignmentId] = useState(defaultAssignmentId);
  const [assignmentUpdateLoading, setAssignmentUpdateLoading] = useState(false);
  const [triggerAssign, setTriggerAssign] = useState(0);

  const { channelId, tenantId } = useClassroom();
  const { toast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const formConfig = useForm({
    resolver: yupResolver(assignmentFormSchema),
    defaultValues: {
      objectives: [{ name: '' }],
    },
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = formConfig;

  const handleAttachmentUpload = (attachment) => {
    dispatch(addAssignmentAttachment({ id: assignmentId, attachment }));
  };

  const createNewAssignment = async () => {
    try {
      setAssignmentCreateLoading(true);
      const assignment = await createAssignment(channelId);
      dispatch(addAssignment(assignment));
      setAssignmentId(assignment.id);
      setAssignmentCreateLoading(false);
    } catch (e) {
      errorHandler(e, () => {
        setAssignmentCreateLoading(false);
        toast({
          description: 'Error occurred during assignment creation',
          variant: 'destructive',
        });
        dispatch(
          setAppError({ message: 'Error occurred during assignment creation' }),
        );
      });
    }
  };

  const onSubmit = async (data) => {
    try {
      setAssignmentUpdateLoading(true);
      const response = await updateAssignmentApi(
        channelId,
        assignmentId,
        formatPayload(data),
      );
      toast({ description: 'Assignment updated successfully' });
      dispatch(updateAssignment(response));
      setTriggerAssign(triggerAssign + 1);
      setAssignmentUpdateLoading(false);
    } catch (e) {
      setAssignmentUpdateLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred during assignment update',
          variant: 'error',
        });
      });
    }
  };

  const handleStudentAssignDone = () => {
    navigate(
      `/teams/${tenantId}/channels/${channelId}/assignment/${currentAssignment.id}`,
    );
  };

  const handleStudentAssignError = (error) => {
    console.log(error);
  };

  const handleFormError = (error) => {
    console.log(error);
    toast({
      description: 'The form is invalid',
      variant: 'destructive',
    });
  };

  const formatPayload = (data) => {
    return {
      ...data,
      due_date: dateToEpoch(data.due_date, true),
      objectives_attributes: data.objectives,
    };
  };

  useEffect(() => {
    if (context === 'AssignmentNew') createNewAssignment();
  }, [context]);

  useEffect(() => {
    if (currentAssignment) {
      const oneWeekFromNow = new Date();
      oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
      const dueDate = currentAssignment?.due_date
        ? new Date(currentAssignment?.due_date)
        : oneWeekFromNow;

      setValue('title', currentAssignment?.title);
      setValue('description', currentAssignment?.description);

      if (currentAssignment?.objectives?.length > 0) {
        setValue('objectives', currentAssignment?.objectives);
      }
      setValue('grade_display', currentAssignment?.grade_display);
      setValue('max_points', currentAssignment?.max_points);

      setValue('due_date', dueDate);
    }
  }, []);

  return (
    <>
      {assignmentCreateLoading && <div>Loading...</div>}

      {!assignmentCreateLoading && (
        <>
          <Form className='mb-5'>
            <div className='mb-3'>
              <Input
                label='Title'
                error={errors?.title?.message}
                {...register('title')}
              />
            </div>

            <div className='mb-3'>
              <TextArea
                id='description'
                placeholder='Description'
                label='Description'
                error={errors?.description?.message}
                {...register('description')}
              />
            </div>

            <div className='mb-3'>
              <FileUploader
                attachableParams={{ work_id: currentAssignment?.id }}
                onUpload={handleAttachmentUpload}
              />
              <div className='mt-3'>
                <Attachments attachments={currentAssignment?.attachments} />
              </div>
            </div>

            <div className='mb-3'>
              <Label>Learning Objectives</Label>
              <LearningObjectivesFields
                formConfig={formConfig}
                control={control}
              />
            </div>

            <div className='mb-3'>
              <Label htmlFor='due_date'>Due On</Label>
              <div>
                <Controller
                  name='due_date'
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <DateInput
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className='mb-3'>
              <Controller
                name='grade_display'
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <Select
                      className='w-full'
                      label='Grading Type'
                      error={errors?.grade_display?.message}
                      options={[
                        { label: 'Points', value: 'grade_display_points' },
                        { label: 'Rubrics', value: 'grade_display_rubrics' },
                      ]}
                      onValueChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  );
                }}
              />
            </div>

            <div className='mb-4'>
              <Input
                type='number'
                label='Max Points'
                error={errors?.max_points?.message}
                {...register('max_points')}
              />
            </div>
          </Form>

          <div className='mb-3'>
            <StudentsTable
              currentAssignment={currentAssignment}
              triggerAssign={triggerAssign}
              onError={handleStudentAssignError}
              onAssign={handleStudentAssignDone}
            />
          </div>

          <Button
            className='w-full'
            withLoader={true}
            loading={assignmentUpdateLoading}
            loaderText='Loading...'
            onClick={handleSubmit(onSubmit, handleFormError)}
          >
            Create
          </Button>
        </>
      )}
    </>
  );
};

AssignmentForm.defaultProps = {
  context: 'AssignmentNew',
  defaultAssignmentId: null,
};

AssignmentForm.propTypes = {
  context: PropTypes.oneOf(['AssignmentNew']),
  defaultAssignmentId: PropTypes.string,
};

export default AssignmentForm;
