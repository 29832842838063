import { useCurrentUserAssignment } from '../../../../../store/selectors';
import { useParams } from 'react-router-dom';
import User from '../../../../../components/user/User';
import { BaseLearningOutcome } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const LearningOutcomesState = ({ showScrollableSummary }) => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const currentAssignment = currentUserAssignment?.work;
  const feedback = currentUserAssignment?.submissions?.[0]?.feedback || {};

  const objectivesWithFeedback = currentAssignment.objectives
    .filter((objective) => feedback.objectives.includes(objective.id))
    .map((objective) => objective.id);

  return (
    <div>
      {!showScrollableSummary && (
        <div className='mb-5'>
          <div className='text-2xl font-normal mb-1'>Feedback</div>
          <div className='text-[#344054]'>{currentAssignment.title}</div>
        </div>
      )}

      <div className='mb-5'>
        <User user={currentAssignment?.user} />
      </div>

      <p className='mb-5 text-[#344054]'>
        Hey {currentUserAssignment?.user?.display_name?.split(' ')?.[0]}
      </p>

      <p className='mb-5 text-[#344054]'>
        Here is how you did on the <strong>learning outcomes</strong> for this
        assessment!
      </p>

      {currentAssignment?.objectives?.map((objective, index) => {
        return (
          <div className='mb-3' key={objective.id}>
            {objectivesWithFeedback.includes(objective.id) ? (
              <BaseLearningOutcome
                body={objective.name}
                footer='Needs Work'
                footerBgColor='[#FEF3F2]'
                footerEnabled
                footerIcon='red-exclamation'
                iconBgColor='[#FECDCA]'
                number={index + 1}
              />
            ) : (
              <BaseLearningOutcome
                body={objective.name}
                footer='Success'
                footerBgColor='[#F0FDF9]'
                footerEnabled
                footerIcon='green-circle-check'
                iconBgColor='[#99F6E0]'
                number={index + 1}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

LearningOutcomesState.propTypes = {
  showScrollableSummary: PropTypes.bool.isRequired,
};

export default LearningOutcomesState;
