import PropTypes from 'prop-types';
import { Avatar } from '@gonurture/design-system';

const User = ({ user }) => {
  return (
    <div className='flex items-center'>
      <Avatar src={user.avatar_url} />

      <div className='ml-2 font-semibold font-normal'>
        {user.display_name || user.email}
      </div>
    </div>
  );
};

User.defaultProps = {
  user: {},
};

User.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    display_name: PropTypes.string,
    email: PropTypes.string,
    avatar_url: PropTypes.string,
  }).isRequired,
};

export default User;
