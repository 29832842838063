import { Button, NurtureIcon } from '@gonurture/design-system';
import PropTypes from 'prop-types';

export const NAVS = { dashboard: 'dashboard', tasks: 'tasks' };

const TeacherHomeNav = ({ activeNav, onTabChange, onNewTask }) => {
  const activeNavClass =
    'text-[#182230] bg-white px-3 py-2 cursor-pointer rounded-md flex items-center transition duration-300 mb-3 md:mb-0';
  const nonActiveNavClass =
    'text-[#667085] cursor-pointer flex items-center transition duration-300 mb-3 md:mb-0';

  const handleTabClick = (tab) => {
    onTabChange(tab);
  };

  return (
    <div className='bg-[#F4F3FF] p-4 rounded'>
      <div className='md:container md:mx-auto md:flex md:justify-between md:items-center'>
        {/*Left Side*/}
        <div className='md:flex md:items-center md:space-x-10 md:flex-grow'>
          <NurtureIcon className='mb-3 md:mb-0' icon='nurture-logo' />

          {/*Nav Items*/}
          <nav className='md:flex md:space-x-5'>
            <div
              className={
                activeNav === NAVS.dashboard
                  ? activeNavClass
                  : nonActiveNavClass
              }
              onClick={() => handleTabClick(NAVS.dashboard)}
            >
              <NurtureIcon icon='grey-star-on-graph' />
              <span className='ml-2'>Dashboard</span>
            </div>
            <div
              className={
                activeNav === NAVS.tasks ? activeNavClass : nonActiveNavClass
              }
              onClick={() => handleTabClick(NAVS.tasks)}
            >
              <NurtureIcon icon='colorful-check-in-circle' />
              <span className='ml-2'>Tasks</span>
            </div>
          </nav>
        </div>

        {/*Right Side*/}
        <div className='flex flex-wrap space-x-4 items-center'>
          <Button
            className='bg-[#7F56D9] text-white hover:bg-[#7F56D9]'
            onClick={onNewTask}
          >
            New Task
          </Button>
          <div className='cursor-pointer hover:p-1 hover:bg-[#E9D7FE] rounded-full transition-all'>
            <NurtureIcon icon='grey-settings' />
          </div>
        </div>
      </div>
    </div>
  );
};

TeacherHomeNav.defaultProps = {
  onTabChange: () => {},
  onNewTask: () => {},
};

TeacherHomeNav.propTypes = {
  activeNav: PropTypes.oneOf(Object.values(NAVS)).isRequired,
  onTabChange: PropTypes.func,
  onNewTask: PropTypes.func,
};

export default TeacherHomeNav;
