import { useNavigate, useParams } from 'react-router-dom';
import HeaderWithBackButton from 'components/header-with-back-button/HeaderWithBackButton';
import { useClassroom, useCurrentAssignment } from 'store/selectors';
import {
  Button,
  Label,
  NurtureIcon,
  Select,
  Switch,
  TextArea,
  useToast,
} from '@gonurture/design-system';
import { ASSIGNMENT_TYPES } from '../assignment-type/AssignmentType';
import { createAssignment } from 'apis/';
import {
  addAssignment,
  addAssignmentAttachment,
} from 'store/reducers/assignments-reducer';
import { setAppError } from 'store/reducers/app-error-reducer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'hooks/';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { assignmentAssistantFormSchema } from 'form-schemas/';
import FileUploader from 'components/attachments/FileUploader';
import Attachments from 'components/attachments/Attachments';

const AssignmentAssistant = () => {
  const [assignmentCreateLoading, setAssignmentCreateLoading] = useState(false);
  const [justUploadedAttachments, setJustUploadedAttachments] = useState([]);
  const [assignmentId, setAssignmentId] = useState(null);
  const { assignmentType } = useParams();
  const { channelId, tenantId } = useClassroom();
  const navigate = useNavigate();

  const { toast } = useToast();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const formConfig = useForm({
    resolver: yupResolver(assignmentAssistantFormSchema),
    defaultValues: {
      time_boundary: false,
      curriculum_boundary: false,
    },
  });

  const {
    register,
    setValue,
    formState: { errors },
    control,
    handleSubmit,
  } = formConfig;

  const assessmentDisplayOptions = [
    { label: 'Long Questions', value: 'long_questions' },
    { label: 'Short Questions', value: 'short_questions' },
    { label: 'Multiple Choice Questions', value: 'multiple_choice_questions' },
    { label: 'Essay Style', value: 'essay_style' },
  ];

  const goToAssignmentNewPage = () => {
    navigate(
      `/teams/${tenantId}/channels/${channelId}/assignment/new/${assignmentType}`,
    );
  };

  const handleBack = () =>
    navigate(`/teams/${tenantId}/channels/${channelId}/assignment/type`);

  const createNewAssignment = async () => {
    try {
      setAssignmentCreateLoading(true);
      const assignment = await createAssignment(channelId);
      dispatch(addAssignment(assignment));
      setAssignmentId(assignment.id);
      setAssignmentCreateLoading(false);
    } catch (e) {
      errorHandler(e, () => {
        setAssignmentCreateLoading(false);
        toast({
          description: 'Error occurred during assignment creation',
          variant: 'destructive',
        });
        dispatch(
          setAppError({ message: 'Error occurred during assignment creation' }),
        );
      });
    }
  };

  const handleAttachmentUpload = (attachment) => {
    setJustUploadedAttachments((prev) => [...prev, attachment]);
  };

  const handleAttachmentReset = () => {
    justUploadedAttachments.forEach((attachment) => {
      dispatch(addAssignmentAttachment({ id: assignmentId, attachment }));
    });

    setJustUploadedAttachments(() => []);
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  useEffect(() => {
    setValue('assessment_grading_type', assignmentType);
    createNewAssignment();
  }, []);

  const Header = () => {
    return (
      <div className='flex space-x-2'>
        <NurtureIcon icon={ASSIGNMENT_TYPES[assignmentType].icon} />
        <div>{ASSIGNMENT_TYPES[assignmentType].title} Task</div>
      </div>
    );
  };

  return (
    <div>
      {assignmentCreateLoading && <>Loading...</>}

      {!assignmentCreateLoading && (
        <>
          <HeaderWithBackButton header={<Header />} onBack={handleBack} />

          <div className='lg:w-1/2 md:w-3/4 md:mx-auto'>
            <div className='mb-8'>
              <TextArea
                id='teacher_instruction'
                placeholder='Enter Topic...'
                label='What topic do you want this assessment to be on?'
                error={errors?.teacher_instruction?.message}
                {...register('teacher_instruction')}
              />
            </div>

            <div className='mb-8'>
              <Label>Create questions from an attachment</Label>
              <div className='text-sm mt-1 text-[#475467]'>
                Upload a reading comprehension, photo of a textbook, paste a URL
                of a webpage, YouTube video or anything you want and the Nurture
                Assistant will generate questions on it.
              </div>

              <div className='mt-3'>
                <FileUploader
                  attachableParams={{ work_id: currentAssignment?.id }}
                  onUpload={handleAttachmentUpload}
                  onReset={handleAttachmentReset}
                />
              </div>
            </div>

            <div className='mb-8'>
              <Attachments attachments={currentAssignment?.attachments} />
            </div>

            <div className='mb-8'>
              <Controller
                name='assessment_description_type'
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <Select
                      className='w-full'
                      label='Assessment Type'
                      error={errors?.assessment_description_type?.message}
                      options={assessmentDisplayOptions}
                      onValueChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  );
                }}
              />
              <p className='text-sm text-[#475467]'>
                Assessment description will contain these types of questions.
              </p>
            </div>

            <div className='mb-8'>
              <div className='flex space-x-3 items-start'>
                <Controller
                  name='time_boundary'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Switch
                        name='time_boundary'
                        checked={value}
                        onCheckedChange={onChange}
                      />
                    );
                  }}
                />
                <div className='flex-grow'>
                  <div className='mb-2 text-[#344054] font-medium'>
                    Time Boundary
                  </div>
                  <div className='text-[#475467]'>
                    Suggest how long students should spend completing this
                    assessment and Nurture assistant will generate content that
                    matches that time range.
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-8'>
              <div className='flex space-x-3 items-start'>
                <Controller
                  name='curriculum_boundary'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Switch
                        name='curriculum_boundary'
                        checked={value}
                        onCheckedChange={onChange}
                      />
                    );
                  }}
                />
                <div className='flex-grow'>
                  <div className='mb-2 text-[#344054] font-medium'>
                    Curriculum Boundary
                  </div>
                  <div className='text-[#475467]'>
                    Nurture Assistant will generate content based on your
                    uploaded curriculum.
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-5'>
              <Button
                className='bg-[#7F56D9] hover:bg-[#7F56D9] text-white w-full'
                onClick={handleSubmit(onSubmit)}
              >
                Generate Task
              </Button>
            </div>

            <div
              className='text-center text-[#7341C6] font-semibold cursor-pointer'
              onClick={goToAssignmentNewPage}
            >
              Skip this step and create this task manually
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AssignmentAssistant;
