import { object, string, boolean } from 'yup';

const assignmentAssistantFormSchema = object({
  teacher_instruction: string().nullable().required('This field is required'),
  assessment_grading_type: string().nullable().required(),
  assessment_description_type: string()
    .nullable()
    .required('This field is required'),
  time_boundary: boolean(),
  curriculum_boundary: boolean(),
});

export default assignmentAssistantFormSchema;
