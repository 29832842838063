import {
  BaseFile,
  Button,
  bytesToHumanReadable,
  FileUpload,
  Label,
} from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useErrorHandler } from 'hooks/';
import { useClassroom } from 'store/selectors';
import { addAttachment } from 'apis/';

const FileUploader = ({ attachableParams, onUpload, onReset }) => {
  const [files, setFiles] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileUploading, setFileUploading] = useState({});
  const [justUploaded, setJustUploaded] = useState(false);
  const [uploadError, setUploadError] = useState({});
  const [reset, setReset] = useState(false);

  const errorHandler = useErrorHandler();
  const { channelId } = useClassroom();

  const uploadProgressMethod = (file) => (progressEvent) => {
    const progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      [file.name]: progress,
    }));
  };

  const handleUpload = async (files) => {
    try {
      setFiles(() => files);
      setUploading(true);
      setReset(false);
      const uploadPromises = Array.from(files).map((file) => uploadFile(file));
      await Promise.all(uploadPromises);
    } catch (e) {
      errorHandler(e);
    }
  };

  const uploadFile = async (file) => {
    try {
      setFileUploading((prev) => ({
        ...prev,
        [file.name]: true,
      }));

      const form = new FormData();
      form.append('file', file);
      for (let key in attachableParams) {
        form.append(key, attachableParams[key]);
      }
      const attachment = await addAttachment(
        channelId,
        form,
        uploadProgressMethod(file),
      );
      onUpload(attachment);
      setJustUploaded((prev) => ({
        ...prev,
        [file.name]: true,
      }));
      return attachment;
    } catch (e) {
      errorHandler(e, () => {
        setUploadError((prev) => ({
          ...prev,
          [file.name]: 'Error occurred when uploading attachment',
        }));
      });
      return Promise.reject(e);
    }
  };

  const attachmentPropertiesFromUploadedFile = (file) => {
    return {
      filename: file.name,
      filesize: bytesToHumanReadable(file.size),
      filetype: file.type.split('/')[1],
    };
  };

  const handleDelete = (file) => {
    const filesArray = Array.from(files);
    const filteredArray = filesArray.filter((f) => f.name !== file.name);

    const dataTransfer = new DataTransfer();
    filteredArray.forEach((f) => dataTransfer.items.add(f));
    setFiles(() => dataTransfer.files);
  };

  const handleAddMore = () => {
    setReset(true);
    if (typeof onReset === 'function') onReset();
  };

  return (
    <div>
      <Label className='mb-3'>Upload Attachments</Label>

      {(!uploading || reset || Array.from(files).length < 1) && (
        <>
          <FileUpload onUpload={handleUpload} />
        </>
      )}

      {uploading && !reset && (
        <div>
          {Array.from(files).map((file) => {
            return (
              <div className='mb-2' key={file.name}>
                <BaseFile
                  uploading={!!fileUploading[file.name]}
                  uploadingPercentage={uploadProgress[file.name]}
                  justUploaded={justUploaded[file.name]}
                  error={uploadError[file.name]}
                  onDelete={() => handleDelete(file)}
                  {...attachmentPropertiesFromUploadedFile(file)}
                />
              </div>
            );
          })}

          <div className='mt-4 flex justify-end'>
            <Button outline onClick={handleAddMore}>
              Add more
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

FileUploader.defaultProps = {
  onUpload: () => {},
  onReset: () => {},
};

FileUploader.propTypes = {
  attachableParams: PropTypes.object.isRequired,
  onUpload: PropTypes.func,
  onReset: PropTypes.func,
};

export default FileUploader;
