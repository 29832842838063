import {
  Accordion,
  Avatar,
  Checkbox,
  useToast,
} from '@gonurture/design-system';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setClassroomMembers } from 'store/reducers/classroom-reducer';
import { useClassroom } from 'store/selectors';
import { useErrorHandler } from 'hooks/';
import PropTypes from 'prop-types';
import { bulkAssign, bulkUnAssign, getMembers } from 'apis/';

const StudentsTable = ({
  currentAssignment,
  triggerAssign,
  onAssign,
  onError,
}) => {
  const [fetchingMembers, setFetchingMembers] = useState(false);
  const [error, setError] = useState('');
  const [assignables, setAssignables] = useState([]);
  const [unassignables, setUnassignables] = useState([]);

  const errorHandler = useErrorHandler();

  const dispatch = useDispatch();
  const { toast } = useToast();
  const { channelId, students } = useClassroom();
  const fetchMembers = async () => {
    try {
      setFetchingMembers(true);
      setError('');
      const members = await getMembers(channelId);
      dispatch(setClassroomMembers(members));
      setFetchingMembers(false);
    } catch (e) {
      setFetchingMembers(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching students',
          variant: 'error',
        });
        setError('Error occurred when fetching students');
      });
    }
  };

  const initialize = async () => {
    await fetchMembers();
    setAssignables(() => {
      return currentAssignment?.user_works?.map(
        (user_work) => user_work.user.id,
      );
    });
  };

  const isAssigned = (studentId) => {
    return currentAssignment?.user_works?.find(
      (user_work) => user_work?.user?.id === studentId,
    );
  };

  const handleCheckedChanged = async (studentId, checked) => {
    if (checked) {
      setAssignables((prev) => [...prev, studentId]);
      setUnassignables((prev) =>
        prev.filter((student) => student !== studentId),
      );
    } else {
      if (isAssigned(studentId)) {
        setUnassignables((prev) => [...prev, studentId]);
      }
      setAssignables((prev) => prev.filter((student) => student !== studentId));
    }
  };

  const handleAssign = async () => {
    try {
      const assignPayload = {
        work_id: currentAssignment.id,
        user_ids: assignables,
      };
      await bulkAssign(channelId, assignPayload);

      if (unassignables.length > 0) {
        const unassignPayload = {
          work_id: currentAssignment.id,
          user_ids: unassignables,
        };
        await bulkUnAssign(channelId, unassignPayload);
      }
      onAssign();
    } catch (e) {
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when assigning students',
          variant: 'error',
        });
        onError();
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (triggerAssign > 0) {
      handleAssign();
    }
  }, [triggerAssign]);

  const Trigger = () => {
    return <h3>Students</h3>;
  };

  const Content = () => {
    return (
      <div className='mx-auto'>
        {fetchingMembers ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className='overflow-x-auto'>
            <table className='min-w-full bg-white border border-gray-200'>
              <thead>
                <tr className='bg-gray-50'>
                  <th className='py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold text-gray-700'>
                    Actions
                  </th>
                  <th className='py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold text-gray-700'>
                    Avatar
                  </th>
                  <th className='py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold text-gray-700'>
                    Name
                  </th>
                  <th className='py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold text-gray-700'>
                    Email
                  </th>
                  <th className='py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold text-gray-700'>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key={student.id}>
                    <td className='py-2 px-4 border-b border-gray-200'>
                      <Checkbox
                        name={student.id}
                        value={student.id}
                        checked={assignables?.includes(student.id)}
                        onCheckedChange={(e) =>
                          handleCheckedChanged(student.id, e)
                        }
                      />
                    </td>
                    <td className='py-2 px-4 border-b border-gray-200'>
                      <Avatar
                        src={student.avatar_url || undefined}
                        width='40'
                        height='40'
                      />
                    </td>
                    <td className='py-2 px-4 border-b border-gray-200'>
                      {student.display_name}
                    </td>
                    <td className='py-2 px-4 border-b border-gray-200'>
                      {student.email}
                    </td>
                    <td className='py-2 px-4 border-b border-gray-200'>
                      {isAssigned(student.id) ? 'Assigned' : 'Unassigned'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Accordion
        className='w-full'
        trigger={<Trigger />}
        content={<Content />}
      />
    </div>
  );
};

StudentsTable.propTypes = {
  currentAssignment: PropTypes.object.isRequired,
  triggerAssign: PropTypes.number.isRequired,
  onAssign: PropTypes.number.isRequired,
  onError: PropTypes.number.isRequired,
};
export default StudentsTable;
