import StorageService from 'services/StorageService';
import { useNavigate } from 'react-router-dom';
import AssignmentList from '../AssignmentList';
import TeacherHomeNav, { NAVS } from './TeacherHomeNav';
import { useState } from 'react';

const Home = () => {
  const [activeNav, setActiveNav] = useState(NAVS.tasks);

  const user = StorageService.getObject('user');
  const navigate = useNavigate();

  const assignmentTypePage = () => {
    navigate('assignment/type');
  };

  const greeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };

  return (
    <div>
      <div className='mb-8 text-2xl'>
        &#128075; {greeting()} {user.display_name.split()[0]}
      </div>

      <TeacherHomeNav
        activeNav={activeNav}
        onTabChange={setActiveNav}
        onNewTask={assignmentTypePage}
      />

      {activeNav === NAVS.tasks && (
        <div className='mt-10'>
          <AssignmentList />
        </div>
      )}

      {activeNav === NAVS.dashboard && (
        <div className='mt-10'>
          <div>Dashboard</div>
        </div>
      )}
    </div>
  );
};

export default Home;
