import './App.css';
import AppRoutes from './AppRoutes';
import ErrorBoundary from './ErrorBoundary';
import { Provider } from 'react-redux';
import store from './store';
import { Toaster } from '@gonurture/design-system';
import AppError from './pages/app-error/AppError';

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <AppError>
          <Toaster />
          <div className='md:container md:mx-auto py-8'>
            <AppRoutes />
          </div>
        </AppError>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
